
.info-container {
  padding: 2rem;
  width: 100%;
  overflow-y: auto;
}


.info-container__header {
  width: 100%;
  font-size: 16pt;
  font-family: Georgia, 'Times New Roman', Times, serif
 
}

.info-container__errors {
  width: 100%;
  margin: 2rem;
  margin-left: 0;
}