@import '../../scss/config/all';
@import '../../scss/components/masthead-link.scss';

.masthead {
  width: 100%;
  @extend %grid;
  @extend %grid_row;
  @extend %align-center;
  @extend %justify-start;
  @extend %theme-mako;
  transition: transform $ease-main $speed-std;

  &[data-considerate="true"] {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $z-masthead;
  }

  &[data-considerate="true"][data-active="true"] {
    transform: none;
  }

  &[data-considerate="true"][data-active="false"] {
    transform: translateY(-100%);
  }


  .company-logo {
    // @TODO: Dummy styles until we get a real SVG logo!
    @extend %masthead-link;
    padding-top: 0;
    padding-bottom: 0;

    img {
      display: block;
      max-width: 100%;
      max-height: 2rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  a {
    color: setDynamicInvertColor($clr_faze-mako);
  }

  .button-logout {
    margin-left: auto;
    margin-right: 0.5rem;
  }

  .rightComponents {
    display: flex;
    position: absolute;
    right: 0;
    align-items: center;
  }
}
