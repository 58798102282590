.navigation {
  @import '../../../scss/config/all';
  @import '../../../scss/components/masthead-link.scss';

  .navigation_list {
    @extend %semantic-list;
    @extend %horizontal-list;

    li {

      a {
        @extend %masthead-link;
      }
    }
  }
}